import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { BsSearch } from "react-icons/bs";

import { HiUsers } from "react-icons/hi";
import { useNavigate } from "react-router-dom";

import useFetch from "../../../ApiClient/GetApi";
import { PostApi } from "../../../ApiClient/PostApi";
import customStyles from "../../dataTable/CustomStyles";
import { Loader2 } from "../../loader/Loader";
import { error_toaster, success_toaster } from "../../toaster/Toaster";
import Leftbar from "../layout/Leftbar";
import Topbar from "../layout/Topbar";
import PropTypes from "prop-types";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { error } from "jquery";
const Plan = ({ placeholder }) => {
  const navigate = useNavigate();
  const { data, reFetch } = useFetch("get_all_plans");
  console.log(data?.data);
  const {
    isOpen: ModalOpen,
    onOpen: ModalOnOpen,
    onClose: ModalOnClose,
  } = useDisclosure();
  const {
    isOpen: UpdateModalOpen,
    onOpen: UpdateModalOnOpen,
    onClose: UpdateModalOnClose,
  } = useDisclosure();
  const [loading, setLoading] = useState(false);

  const [edit, setEdit] = useState({
    title: "",
    price: "",
    id: "",
  });

  const [shortDesc, setShortDesc] = useState("");
  const [category, setCategory] = useState("");
  const [longDesc, setLongDesc] = useState("");
  const [theme, setTheme] = useState("snow");

  const [mondayStart, setMondayStart] = useState("");
  const [mondayEnd, setMondayEnd] = useState("");

  const [tuesdayStart, settuesdayStart] = useState("");
  const [tuesdayEnd, settuesdayEnd] = useState("");

  const [wednesdayStart, setwednesdayStart] = useState("");
  const [wednesdayEnd, setwednesdayEnd] = useState("");

  const [thursdayStart, setthursdayStart] = useState("");
  const [thursdayEnd, setthursdayEnd] = useState("");

  const [fridayStart, setfridayStart] = useState("");
  const [fridayEnd, setfridayEnd] = useState("");

  const [saturdayStart, setsaturdayStart] = useState("");
  const [saturdayEnd, setsaturdayEnd] = useState("");

  const [sundayStart, setsundayStart] = useState("");
  const [sundayEnd, setsundayEnd] = useState("");

  const [selectedImage, setSelectedImage] = useState("");
  const [categoryName, setCategoryName] = useState("");

  const [zoomLink, setZoomLink] = useState("");

  const handleShortDesc = (html) => {
    setShortDesc(html);
  };
  const handleLongDesc = (html) => {
    setLongDesc(html);
  };

  const onChange = (e) => {
    setEdit({ ...edit, [e.target.name]: e.target.value });
  };

  const block_plan = async (id) => {
    setLoading(true);
    const res = await PostApi("block_plan", {
      planId: id,
    });
    if (res.data.status === "1") {
      setLoading(false);
      error_toaster(res.data.message);
      reFetch();
    } else {
      error_toaster(res.data.message);
      setLoading(false);
    }
  };
  const activate_plan = async (id) => {
    setLoading(true);
    const res = await PostApi("activate_plan", {
      planId: id,
    });
    if (res.data.status === "1") {
      setLoading(false);
      success_toaster(res.data.message);
      reFetch();
    } else {
      error_toaster(res.data.message);
      setLoading(false);
    }
  };

  const add_plan = async () => {
    const formData = new FormData();
    formData.append("title", edit.title);
    formData.append("price", edit.price);
    formData.append("shortDescription", shortDesc);
    formData.append("longDescription", longDesc);
    formData.append("image", selectedImage);

    formData.append("mondayStart", mondayStart);
    formData.append("mondayEnd", mondayEnd);

    formData.append("tuesdayStart", tuesdayStart);
    formData.append("tuesdayEnd", tuesdayEnd);

    formData.append("wednesdayStart", wednesdayStart);
    formData.append("wednesdayEnd", wednesdayEnd);

    formData.append("thursdayStart", thursdayStart);
    formData.append("thursdayEnd", thursdayEnd);

    formData.append("fridayStart", fridayStart);
    formData.append("fridayEnd", fridayEnd);

    formData.append("saturdayStart", saturdayStart);
    formData.append("saturdayEnd", saturdayEnd);

    formData.append("sundayStart", sundayStart);
    formData.append("sundayEnd", sundayEnd);

    formData.append("category", category);
    formData.append("zoomLink", zoomLink);

    const res = await PostApi("add_plan", formData);
    if (res.data.status === "1") {
      success_toaster(res.data.message);
      setLoading(false);
      reFetch();
      ModalOnClose();
    } else {
      error_toaster(res.data.message);
      setLoading(false);
    }
  };

  const update_plan = (plan) => {
    setEdit({ title: plan.title, price: plan.price, id: plan.id });
    setShortDesc(plan.shortDescription);
    setLongDesc(plan.longDescription);
    UpdateModalOnOpen();
  };

  const setPlanCategory = (event) =>{
    const selectedOption = event.target.options[event.target.selectedIndex];
    const categoryName = selectedOption.getAttribute('categoryName');
    setCategoryName(categoryName)
    setCategory(event.target.value);
  }
  console.log(category)
  const update_plan_post = async () => {
    const formData = new FormData();
    formData.append("planId", edit.id);
    formData.append("title", edit.title);
    formData.append("price", edit.price);
    formData.append("shortDescription", shortDesc);
    formData.append("longDescription", longDesc);
    formData.append("image", selectedImage);
    const res = await PostApi("update_plan", formData);
    if (res.data.status === "1") {
      success_toaster(res.data.message);
      setLoading(false);
      reFetch();
      UpdateModalOnClose();
    } else {
      error_toaster(res.data.message);
      setLoading(false);
    }
  };

  const columns = [
    {
      name: "S.No",
      selector: (row) => row.id,
    },

    {
      name: "Plan ID",
      selector: (row) => row.planId,
    },

    {
      name: "Title",
      selector: (row) => row.title,
      wrap: true,
      width: "250px",
    },
    {
      name: "Price ($)",
      selector: (row) => row.price,
      wrap: true,
      width: "250px",
    },

    {
      name: "Status",
      selector: (row) => row.status,
    },
    {
      name: "Action",
      selector: (row) => row.action,
    },
  ];
  const [query, setQuery] = useState("");
  const datas = [];
  data?.data?.plans?.map((user, index) => {
    if (query == "") {
      return datas.push({
        id: index + 1,
        planId: user.id,
        title: user.title,
        price: user.price,

        status:
          user.status === true ? (
            <button
              onClick={() => block_plan(user.id)}
              className="bg-themeBlue3 text-themeBlue font-medium text-xs px-4 py-1 rounded-md"
            >
              Active
            </button>
          ) : (
            <button
              onClick={() => activate_plan(user.id)}
              className="bg-themeRed2 text-themeRed font-medium text-xs px-4 py-1 rounded-md"
            >
              Block
            </button>
          ),
        action: (
          <>
            <div className="flex gap-x-2">
              <button
                size={36}
                className="py-1 px-2 bg-themeBlue text-white rounded border border-themeBlue hover:bg-white hover:text-themeBlue hover:cursor-pointer"
                onClick={() => update_plan(user)}
              >
                Update
              </button>
            </div>
          </>
        ),
      });
    } else if (
      user.id.toString().toLowerCase().match(query.toString().toLowerCase())
    ) {
      return datas.push({
        id: index + 1,
        planId: user.id,
        title: user.title,
        price: user.price,

        status:
          user.status === true ? (
            <button
              onClick={() => block_plan(user.id)}
              className="bg-themeBlue3 text-themeBlue font-medium text-xs px-4 py-1 rounded-md"
            >
              Active
            </button>
          ) : (
            <button
              onClick={() => activate_plan(user.id)}
              className="bg-themeRed2 text-themeRed font-medium text-xs px-4 py-1 rounded-md"
            >
              Block
            </button>
          ),
        action: (
          <>
            <div className="flex gap-x-2">
              <button
                size={36}
                className="py-1 px-2 bg-themeBlue text-white rounded border border-themeBlue hover:bg-white hover:text-themeBlue hover:cursor-pointer"
                onClick={() => update_plan(user)}
              >
                Update
              </button>
            </div>
          </>
        ),
      });
    }
  });

  const labelStyle = "font-normal text-sm text-black text-opacity-60";
  const inputStyle =
    "w-full bg-themeGray px-4 py-3 rounded-md border-none font-normal text-base focus:outline-none";
  return (
    <>
      <Leftbar />
      <Topbar main="Plan's Management" extend={false} />
      <section className="relative xl:w-[calc(100%-280px)] w-[calc(100%-240px)] top-[148px] py-5 px-10 float-right space-y-8">
        <div className="flex items-center justify-between">
          <h1 className="flex items-center gap-x-3">
            <HiUsers size={40} className="text-themeBlue" />
            <span className="font-bold text-2xl">All Plans</span>
          </h1>
          <button
            onClick={ModalOnOpen}
            className="bg-themeBlue py-3 px-6 rounded text-white"
          >
            Add Plan
          </button>
          <div className="flex items-center">
            <input
              type="search"
              name="search"
              onChange={(e) => setQuery(e.target.value)}
              placeholder="Ex: Search by ID"
              className="bg-transparent border border-black border-opacity-40 rounded-md py-3 pl-4 pr-14 font-normal text-xl w-80 focus:outline-none"
            />
            <button className="bg-themeBlue flex justify-center items-center py-3 px-3 border border-black border-opacity-40 rounded-md absolute right-10">
              <BsSearch size={28} className="text-white" />
            </button>
          </div>
        </div>
        {loading ? (
          <Loader2 />
        ) : (
          <DataTable
            theme="myTheme"
            customStyles={customStyles}
            columns={columns}
            data={datas}
            pagination
          />
        )}
      </section>

      <Modal
        onClose={UpdateModalOnClose}
        isOpen={UpdateModalOpen}
        size="5xl"
        isCentered
      >
        <ModalOverlay />
        <ModalContent style={{ height: "90vh", overflowY: "scroll" }}>
          <ModalCloseButton />
          {loading ? (
            <Loader2 />
          ) : (
            <ModalBody className="bg-white rounded-lg">
              <div className="py-6 px-3">
                <h1 className="font-medium text-2xl">Update Plan</h1>
                <div className="grid grid-cols-2 gap-x-8 gap-y-4 py-5">
                  <div className="space-y-2">
                    <label htmlFor="order_id" className={labelStyle}>
                      Title
                    </label>
                    <input
                      type="text"
                      onChange={onChange}
                      value={edit.title}
                      name="title"
                      id="BookingId"
                      placeholder="Title"
                      className={inputStyle}
                    />
                  </div>
                  <div className="space-y-2">
                    <label htmlFor="transaction_id" className={labelStyle}>
                      Price($)
                    </label>
                    <input
                      type="number"
                      onChange={onChange}
                      value={edit.price}
                      name="price"
                      id="price"
                      placeholder="Price"
                      className={inputStyle}
                    />
                  </div>
                  <div className="col-span-2">
                    <div className="space-y-20">
                      <div style={{ height: "150px" }}>
                        <h1>Short Description</h1>
                        <ReactQuill
                          theme={theme}
                          onChange={handleShortDesc}
                          value={shortDesc}
                          modules={Plan.modules}
                          formats={Plan.formats}
                          bounds=".app"
                          placeholder={placeholder}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-span-2 my-14">
                    <div className="space-y-20">
                      <div style={{ height: "150px" }}>
                        <h1>Long Description</h1>
                        <ReactQuill
                          theme={theme}
                          onChange={handleLongDesc}
                          value={longDesc}
                          modules={Plan.modules}
                          formats={Plan.formats}
                          bounds=".app"
                          placeholder={placeholder}
                        />
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="flex gap-x-2 gap-y-2 flex-wrap">
                      <div>
                        {selectedImage && (
                          <div>
                            <img
                              className="h-28 w-28 object-cover rounded-xl"
                              alt="New Image"
                              src={URL.createObjectURL(selectedImage)}
                            />
                            <br />
                            <button
                              className="mt-2  btn btn-danger btn-sm button-border"
                              onClick={() => setSelectedImage(null)}
                            >
                              Remove
                            </button>
                          </div>
                        )}
                        <input
                          type="file"
                          name="myImage"
                          onChange={(event) => {
                            setSelectedImage(event.target.files[0]);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-end gap-x-3 py-3">
                  <button
                    onClick={UpdateModalOnClose}
                    className="font-normal text-xl text-themeBlue bg-transparent border border-themeBlue py-1 px-4 rounded-md hover:bg-themeBlue hover:text-white"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={update_plan_post}
                    className="font-normal text-xl text-white bg-themeBlue border border-themeBlue py-1 px-4 rounded-md hover:bg-transparent hover:text-themeBlue"
                  >
                    Update Plan
                  </button>
                </div>
              </div>
            </ModalBody>
          )}
        </ModalContent>
      </Modal>
      <Modal onClose={ModalOnClose} isOpen={ModalOpen} size="5xl" isCentered>
        <ModalOverlay />
        <ModalContent style={{ height: "90vh", overflowY: "scroll" }}>
          <ModalCloseButton />
          {loading ? (
            <Loader2 />
          ) : (
            <ModalBody className="bg-white rounded-lg">
              <div className="py-6 px-3">
                <h1 className="font-medium text-2xl">Add Plan</h1>
                <div className="grid grid-cols-2 gap-x-8 gap-y-4 py-5">
                  <div className="space-y-2">
                    <label htmlFor="order_id" className={labelStyle}>
                      Title
                    </label>
                    <input
                      type="text"
                      onChange={onChange}
                      value={edit.title}
                      name="title"
                      id="BookingId"
                      placeholder="Title"
                      className={inputStyle}
                    />
                  </div>
                 
                  <div className="space-y-2">
                    <label htmlFor="transaction_id" className={labelStyle}>
Price
                    </label>
                    <input
                      type="number"
                      onChange={onChange}
                      value={edit.price}
                      name="price"
                      id="price"
                      placeholder="Price"
                      className={inputStyle}
                    />
                  </div>
                  <div className="col-span-2">
                    <div className="space-y-20">
                      <div style={{ height: "100px" }}>
                        <h1>Short Description</h1>
                        <ReactQuill
                          theme={theme}
                          onChange={handleShortDesc}
                          value={shortDesc}
                          modules={Plan.modules}
                          formats={Plan.formats}
                          bounds=".app"
                          placeholder={placeholder}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-span-2 my-14">
                    <div className="space-y-20">
                      <div style={{ height: "100px" }}>
                        <h1>Long Description</h1>
                        <ReactQuill
                          theme={theme}
                          onChange={handleLongDesc}
                          value={longDesc}
                          modules={Plan.modules}
                          formats={Plan.formats}
                          bounds=".app"
                          placeholder={placeholder}
                        />
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="flex gap-x-2 gap-y-2 flex-wrap">
                      <div>
                        {selectedImage && (
                          <div>
                            <img
                              className="h-28 w-28 object-cover rounded-xl"
                              alt="New Image"
                              src={URL.createObjectURL(selectedImage)}
                            />
                            <br />
                            <button
                              className="mt-2  btn btn-danger btn-sm button-border"
                              onClick={() => setSelectedImage(null)}
                            >
                              Remove
                            </button>
                          </div>
                        )}
                        <input
                          type="file"
                          name="myImage"
                          onChange={(event) => {
                            setSelectedImage(event.target.files[0]);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex items-center gap-x-10 py-4">
                  <p className="text-xl font-semibold">Select Category</p>
                  <div>
                    <select onChange={(e)=>setPlanCategory(e)} className="py-3 rounded px-4">
                      <option>Select Category</option>
                      {data?.data?.categories?.map((category) => (
                        <option categoryName={category.title} value={category.id}>{category.title}</option>
                      ))}
                    </select>
                  </div>
                </div>
                {
                  categoryName == "WorkOut" ?<div>
                  <h1 className="text-xl font-semibold">Set Plan Timing</h1>
                  <div className="space-y-4 my-6">
                    <div className="grid grid-cols-3 gap-x-4 items-center">
                      <p className="font-semibold text-lg">Monday</p>
                      <div className="space-x-4">
                        <label>Start</label>
                        <input
                          onChange={(e) => setMondayStart(e.target.value)}
                          value={mondayStart}
                          type="time"
                          className="rounded"
                        />
                      </div>
                      <div className="space-x-4">
                        <label>End</label>
                        <input
                          onChange={(e) => setMondayEnd(e.target.value)}
                          value={mondayEnd}
                          type="time"
                          className="rounded"
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-3 gap-x-4 items-center">
                      <p className="font-semibold text-lg">Tuesday</p>
                      <div className="space-x-4">
                        <label>Start</label>
                        <input
                          onChange={(e) => settuesdayStart(e.target.value)}
                          value={tuesdayStart}
                          type="time"
                          className="rounded"
                        />
                      </div>
                      <div className="space-x-4">
                        <label>End</label>
                        <input
                          onChange={(e) => settuesdayEnd(e.target.value)}
                          value={tuesdayEnd}
                          type="time"
                          className="rounded"
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-3 gap-x-4 items-center">
                      <p className="font-semibold text-lg">Wednesday</p>
                      <div className="space-x-4">
                        <label>Start</label>
                        <input
                          onChange={(e) => setwednesdayStart(e.target.value)}
                          value={wednesdayStart}
                          type="time"
                          className="rounded"
                        />
                      </div>
                      <div className="space-x-4">
                        <label>End</label>
                        <input
                          onChange={(e) => setwednesdayEnd(e.target.value)}
                          value={wednesdayEnd}
                          type="time"
                          className="rounded"
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-3 gap-x-4 items-center">
                      <p className="font-semibold text-lg">Thursday</p>
                      <div className="space-x-4">
                        <label>Start</label>
                        <input
                          onChange={(e) => setthursdayStart(e.target.value)}
                          value={thursdayStart}
                          type="time"
                          className="rounded"
                        />
                      </div>
                      <div className="space-x-4">
                        <label>End</label>
                        <input
                          onChange={(e) => setthursdayEnd(e.target.value)}
                          value={thursdayEnd}
                          type="time"
                          className="rounded"
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-3 gap-x-4 items-center">
                      <p className="font-semibold text-lg">Friday</p>
                      <div className="space-x-4">
                        <label>Start</label>
                        <input
                          onChange={(e) => setfridayStart(e.target.value)}
                          value={fridayStart}
                          type="time"
                          className="rounded"
                        />
                      </div>
                      <div className="space-x-4">
                        <label>End</label>
                        <input
                          onChange={(e) => setfridayEnd(e.target.value)}
                          value={fridayEnd}
                          type="time"
                          className="rounded"
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-3 gap-x-4 items-center">
                      <p className="font-semibold text-lg">Saturday</p>
                      <div className="space-x-4">
                        <label>Start</label>
                        <input
                          onChange={(e) => setsaturdayStart(e.target.value)}
                          value={saturdayStart}
                          type="time"
                          className="rounded"
                        />
                      </div>
                      <div className="space-x-4">
                        <label>End</label>
                        <input
                          onChange={(e) => setsaturdayEnd(e.target.value)}
                          value={saturdayEnd}
                          type="time"
                          className="rounded"
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-3 gap-x-4 items-center">
                      <p className="font-semibold text-lg">Sunday</p>
                      <div className="space-x-4">
                        <label>Start</label>
                        <input
                          onChange={(e) => setsundayStart(e.target.value)}
                          value={sundayStart}
                          type="time"
                          className="rounded"
                        />
                      </div>
                      <div className="space-x-4">
                        <label>End</label>
                        <input
                          onChange={(e) => setsundayEnd(e.target.value)}
                          value={sundayEnd}
                          type="time"
                          className="rounded"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="space-y-2">
                    <label htmlFor="transaction_id" className={labelStyle}>
                      Zoom Link
                    </label>
                    <input
                      type="text"
                      onChange={(e)=>setZoomLink(e.target.value)}
                      value={zoomLink}
                      name="zoomLink"
                      id="zoomLink"
                      placeholder="zoomLink"
                      className={inputStyle}
                    />
                  </div>
                </div>:""
                }
                
                <div className="flex items-center justify-end gap-x-3 py-3">
                  <button
                    onClick={ModalOnClose}
                    className="font-normal text-xl text-themeBlue bg-transparent border border-themeBlue py-1 px-4 rounded-md hover:bg-themeBlue hover:text-white"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={add_plan}
                    className="font-normal text-xl text-white bg-themeBlue border border-themeBlue py-1 px-4 rounded-md hover:bg-transparent hover:text-themeBlue"
                  >
                    Add Plan
                  </button>
                </div>
              </div>
            </ModalBody>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

Plan.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image", "video"],
    ["clean"],
  ],
  clipboard: {
    matchVisual: false, // toggle to add extra line breaks when pasting HTML
  },
};

Plan.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
];

Plan.propTypes = {
  placeholder: PropTypes.string,
};

export default Plan;
