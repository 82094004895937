import React, { useState } from "react";
import DataTable from "react-data-table-component";


import { HiUsers } from "react-icons/hi";
import { useNavigate } from "react-router-dom";

import useFetch from "../../../ApiClient/GetApi";
import { PostApi } from "../../../ApiClient/PostApi";
import customStyles from "../../dataTable/CustomStyles";
import { Loader2 } from "../../loader/Loader";
import { error_toaster, success_toaster } from "../../toaster/Toaster";
import Leftbar from "../layout/Leftbar";
import Topbar from "../layout/Topbar";

import "react-quill/dist/quill.snow.css";


const Contact = () => {
  const navigate = useNavigate();
  const { data, reFetch } = useFetch("get_all_contact_messages");
  console.log(data);
const [query,setQuery] = useState("")

  const [loading, setLoading] = useState(false);

  const [title, setTitle] = useState("");
  const [id, setId] = useState("");
 

  const columns = [
    {
      name: "S.No",
      selector: (row) => row.id,
    },

    {
      name: "Full Name",
      selector: (row) => row.name,
      wrap: true,
      width: "250px",
    },

    {
      name: "Email",
      selector: (row) => row.email,
    },
    {
      name: "Message",
      selector: (row) => row.message,
    },
    {
      name: "Action",
      selector: (row) => row.action,
    },
  ];

  const datas = [];
  data?.data?.map((message, index) => {
    if (query == "") {
      return datas.push({
        id: index + 1,

        name: `${message.firstName} ${message.lastName}`,
        email: message.email,
        message: message.message,

  
        action: (
          <>
            <div className="flex gap-x-2">
              <a
                href={`mailto:${message.email}`}
                size={36}
                className="py-1 px-2 bg-themeBlue text-white rounded border border-themeBlue hover:bg-white hover:text-themeBlue hover:cursor-pointer"
              >
                Reply
              </a>
            </div>
          </>
        ),
      });
    } else if (
      message.id.toString().toLowerCase().match(query.toString().toLowerCase())
    ) {
        return datas.push({
            id: index + 1,
    
            name: `${message.firstName} ${message.lastName}`,
            email: message.email,
            message: message.message,
    
      
            action: (
              <>
                <div className="flex gap-x-2">
                  <button
                  href={`mailto:${message.email}`}
                    size={36}
                    className="py-1 px-2 bg-themeBlue text-white rounded border border-themeBlue hover:bg-white hover:text-themeBlue hover:cursor-pointer"
                  >
                    Reply
                  </button>
                </div>
              </>
            ),
          });
    }
  });

  const labelStyle = "font-normal text-sm text-black text-opacity-60";
  const inputStyle =
    "w-full bg-themeGray px-4 py-3 rounded-md border-none font-normal text-base focus:outline-none";
  return (
    <>
      <Leftbar />
      <Topbar main="Categories Management" extend={false} />
      <section className="relative xl:w-[calc(100%-280px)] w-[calc(100%-240px)] top-[148px] py-5 px-10 float-right space-y-8">
        <div className="flex items-center justify-between">
          <h1 className="flex items-center gap-x-3">
            <HiUsers size={40} className="text-themeBlue" />
            <span className="font-bold text-2xl">All Categories</span>
          </h1>
          
        </div>
        {loading ? (
          <Loader2 />
        ) : (
          <DataTable
            theme="myTheme"
            customStyles={customStyles}
            columns={columns}
            data={datas}
            pagination
          />
        )}
      </section>


    
    </>
  );
};

export default Contact;
