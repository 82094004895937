import React from "react";
import { FaGreaterThan,FaArrowCircleLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
export default function Topbar(props) {
  let navigate = useNavigate();
  const backFunction = () => {
    navigate(-1);
  };
  return (
    <nav className="absolute xl:w-[calc(100%-280px)] w-[calc(100%-240px)] top-0 xl:ml-[280px] ml-60 py-8 px-10 float-right">
      <div className="bg-white px-5 py-4 flex items-center justify-between rounded-lg">
        <div className="flex items-center gap-x-2">
          <FaArrowCircleLeft  onClick={backFunction} size={24} className="cursor-pointer" />
          <h1
           
            className="font-medium text-2xl"
          >
            {props.main}
          </h1>
          {props.extend && (
            <>
              <span>
                <FaGreaterThan
                  className="text-black text-opacity-60"
                  size={12}
                />
              </span>
              <span className="font-medium text-base text-black text-opacity-60">
                {props.sub}
              </span>
            </>
          )}
        </div>
        <div className="flex items-center gap-x-4">
          <div className="relative">
            {/* <input
              type="search"
              name="search"
              placeholder="Search"
              className="bg-themeGray rounded-md py-3 pl-4 pr-12 font-normal text-xl w-96 focus:outline-none"
            /> */}
            {/* <BsSearch
                size={28}
                className="text-black text-opacity-60 absolute right-2 top-[50%] -translate-y-[50%] cursor-pointer"
              /> */}
          </div>
          {/* <button className="bg-themeBlue p-2 rounded-md text-white border border-themeBlue hover:text-themeBlue hover:bg-transparent hover:border-themeBlue">
            <FaRegBell size={28} />
          </button>
          <button className="bg-themeBlue p-2 rounded-md text-white border border-themeBlue hover:text-themeBlue hover:bg-transparent hover:border-themeBlue">
            <FaRegUser size={28} />
          </button> */}
        </div>
      </div>
    </nav>
  );
}
