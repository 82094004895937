import React from "react";
import { FaBars, FaBriefcase, FaDollarSign, FaUsers } from "react-icons/fa";
import useFetch from "../../../ApiClient/GetApi";
import Leftbar from "../layout/Leftbar";
import Topbar from "../layout/Topbar";
import Card from "./homeElements/Card";

export default function Home() {
  const { data } = useFetch("dashboard");
  console.log(data)
  return (
    <>
      <Leftbar />
      <Topbar main="Dashboard" extend={false} />
      <section className="relative xl:w-[calc(100%-280px)] w-[calc(100%-240px)] top-[148px] py-12 px-10 float-right">
        <div className="grid xl:grid-cols-4 lg:grid-cols-3 grid-cols-2 gap-5">
          <Card
            quantity={data?.data?.plans ?? 0}
            title="Total Plans"
            // size="54"
            colorScheme="bg-themeGray2"
            Icon={FaBars}
          />
          <Card
            quantity={data?.data?.users ?? 0}
            title="All Users"
            // size="75"
            colorScheme="bg-themeGreen"
            Icon={FaUsers}
          />
          <Card
            quantity={data?.data?.usersplans ?? "0"}
            title="User Plans"
            // size="85"
            colorScheme="bg-themePink"
            Icon={FaBriefcase}
          />
          <Card
            quantity={`$${data?.data?.totalAmount ?? "0"}`}
            title="Total Income"
           
            colorScheme="bg-themeBlue2"
            Icon={FaDollarSign}
          />
          <Card
            quantity={data?.data?.categories ?? "0"}
            title="Total Categories"
            // size="78"
            colorScheme="bg-themeBlue2"
            Icon={FaDollarSign}
          />
          <Card
            quantity={data?.data?.services ?? "0"}
            title="Total Services"
            // size="78"
            colorScheme="bg-themePink"
            Icon={FaDollarSign}
          />
        </div>
      </section>
    </>
  );
}
