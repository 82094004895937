import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { BsSearch } from "react-icons/bs";

import { HiUsers } from "react-icons/hi";
import { useNavigate } from "react-router-dom";

import useFetch from "../../../ApiClient/GetApi";
import { PostApi } from "../../../ApiClient/PostApi";
import customStyles from "../../dataTable/CustomStyles";
import { Loader2 } from "../../loader/Loader";
import { error_toaster, success_toaster } from "../../toaster/Toaster";
import Leftbar from "../layout/Leftbar";
import Topbar from "../layout/Topbar";
import PropTypes from "prop-types";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { error } from "jquery";
const Category = () => {
  const navigate = useNavigate();
  const { data, reFetch } = useFetch("all_categories");
  console.log(data);
  const {
    isOpen: ModalOpen,
    onOpen: ModalOnOpen,
    onClose: ModalOnClose,
  } = useDisclosure();
  const {
    isOpen: UpdateModalOpen,
    onOpen: UpdateModalOnOpen,
    onClose: UpdateModalOnClose,
  } = useDisclosure();
  const [loading, setLoading] = useState(false);

  const [title, setTitle] = useState("");
  const [id, setId] = useState("");
  const add_category = async () => {
    setLoading(true)
    const res =await PostApi("add_category", {
      title: title,
    });
    console.log(res);
    if (res.data.status === "1") {
      success_toaster(res.data.message);
      setLoading(false);
      reFetch();
      ModalOnClose();
    } else {
      error_toaster(res.data.message);
      setLoading(false);
    }
  };

  const update_category = (category) =>
  {
    setTitle(category?.title)
    setId(category.id)
    UpdateModalOnOpen()
  }

  const update_category_post =async()=>{
    setLoading(true);
    const res = await PostApi("udpate_category",{
        title:title,
        id:id
    })
    if(res.data.status === "1")
    {
        setLoading(false);
        success_toaster(res.data.message);
        reFetch();
        UpdateModalOnClose();
    }
    else
    {
        error_toaster(res.data.message);
        setLoading(false);
    }
  }

  const columns = [
    {
      name: "S.No",
      selector: (row) => row.id,
    },

    {
      name: "Title",
      selector: (row) => row.title,
      wrap: true,
      width: "250px",
    },

    {
      name: "Status",
      selector: (row) => row.status,
    },
    {
      name: "Action",
      selector: (row) => row.action,
    },
  ];
  const [query, setQuery] = useState("");
  const datas = [];
  data?.data?.map((user, index) => {
    if (query == "") {
      return datas.push({
        id: index + 1,

        title: user.title,

        status:
          user.status === true ? (
            <button className="bg-themeBlue3 text-themeBlue font-medium text-xs px-4 py-1 rounded-md">
              Active
            </button>
          ) : (
            <button className="bg-themeRed2 text-themeRed font-medium text-xs px-4 py-1 rounded-md">
              Block
            </button>
          ),
        action: (
          <>
            <div className="flex gap-x-2">
              <button
              onClick={()=>update_category(user)}
                size={36}
                className="py-1 px-2 bg-themeBlue text-white rounded border border-themeBlue hover:bg-white hover:text-themeBlue hover:cursor-pointer"
              >
                Update
              </button>
            </div>
          </>
        ),
      });
    } else if (
      user.id.toString().toLowerCase().match(query.toString().toLowerCase())
    ) {
      return datas.push({
        id: index + 1,

        title: user.title,

        status:
          user.status === true ? (
            <button className="bg-themeBlue3 text-themeBlue font-medium text-xs px-4 py-1 rounded-md">
              Active
            </button>
          ) : (
            <button className="bg-themeRed2 text-themeRed font-medium text-xs px-4 py-1 rounded-md">
              Block
            </button>
          ),
        action: (
          <>
            <div className="flex gap-x-2">
              <button
                size={36}
                className="py-1 px-2 bg-themeBlue text-white rounded border border-themeBlue hover:bg-white hover:text-themeBlue hover:cursor-pointer"
              >
                Update
              </button>
            </div>
          </>
        ),
      });
    }
  });

  const labelStyle = "font-normal text-sm text-black text-opacity-60";
  const inputStyle =
    "w-full bg-themeGray px-4 py-3 rounded-md border-none font-normal text-base focus:outline-none";
  return (
    <>
      <Leftbar />
      <Topbar main="Categories Management" extend={false} />
      <section className="relative xl:w-[calc(100%-280px)] w-[calc(100%-240px)] top-[148px] py-5 px-10 float-right space-y-8">
        <div className="flex items-center justify-between">
          <h1 className="flex items-center gap-x-3">
            <HiUsers size={40} className="text-themeBlue" />
            <span className="font-bold text-2xl">All Categories</span>
          </h1>
          <button
            onClick={ModalOnOpen}
            className="bg-themeBlue py-3 px-6 rounded text-white"
          >
            Add Category
          </button>
        </div>
        {loading ? (
          <Loader2 />
        ) : (
          <DataTable
            theme="myTheme"
            customStyles={customStyles}
            columns={columns}
            data={datas}
            pagination
          />
        )}
      </section>

      <Modal onClose={UpdateModalOnClose} isOpen={UpdateModalOpen} size="xl" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          {loading ? (
            <Loader2 />
          ) : (
            <ModalBody className="bg-white rounded-lg">
              <div className="py-6 px-3">
                <h1 className="font-medium text-2xl">Add Category</h1>
                <div className=" gap-x-8 gap-y-4 py-5">
                  <div className="space-y-2">
                    <label htmlFor="order_id" className={labelStyle}>
                      Title
                    </label>
                    <input
                      type="text"
                      onChange={(e) => setTitle(e.target.value)}
                      value={title}
                      name="title"
                      id="BookingId"
                      placeholder="Title"
                      className={inputStyle}
                    />
                  </div>
                </div>
                <div className="flex items-center justify-end gap-x-3 py-3">
                  <button
                    onClick={UpdateModalOnClose}
                    className="font-normal text-xl text-themeBlue bg-transparent border border-themeBlue py-1 px-4 rounded-md hover:bg-themeBlue hover:text-white"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={update_category_post}
                    className="font-normal text-xl text-white bg-themeBlue border border-themeBlue py-1 px-4 rounded-md hover:bg-transparent hover:text-themeBlue"
                  >
                    Update Category
                  </button>
                </div>
              </div>
            </ModalBody>
          )}
        </ModalContent>
      </Modal>
      <Modal onClose={ModalOnClose} isOpen={ModalOpen} size="xl" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          {loading ? (
            <Loader2 />
          ) : (
            <ModalBody className="bg-white rounded-lg">
              <div className="py-6 px-3">
                <h1 className="font-medium text-2xl">Add Category</h1>
                <div className=" gap-x-8 gap-y-4 py-5">
                  <div className="space-y-2">
                    <label htmlFor="order_id" className={labelStyle}>
                      Title
                    </label>
                    <input
                      type="text"
                      onChange={(e) => setTitle(e.target.value)}
                      value={title}
                      name="title"
                      id="BookingId"
                      placeholder="Title"
                      className={inputStyle}
                    />
                  </div>
                </div>
                <div className="flex items-center justify-end gap-x-3 py-3">
                  <button
                    onClick={ModalOnClose}
                    className="font-normal text-xl text-themeBlue bg-transparent border border-themeBlue py-1 px-4 rounded-md hover:bg-themeBlue hover:text-white"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={add_category}
                    className="font-normal text-xl text-white bg-themeBlue border border-themeBlue py-1 px-4 rounded-md hover:bg-transparent hover:text-themeBlue"
                  >
                    Add Category
                  </button>
                </div>
              </div>
            </ModalBody>
          )}
        </ModalContent>
      </Modal>
    
    </>
  );
};

export default Category;
