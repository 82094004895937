// @ts-nocheck
import { ChakraProvider } from '@chakra-ui/react'
import React,{useEffect} from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import './App.css'
import ForgetPassword from './components/pages/auth/ForgetPassword'
import Login from './components/pages/auth/Login'
import NewPassword from './components/pages/auth/NewPassword'
import OtpPage from './components/pages/auth/OtpPage'
import Signup from './components/pages/auth/Signup'
import Home from './components/pages/home/Home'

import Users from './components/pages/users/Users'
import ProtectedRoutes from './Utils/ProtectedRoutes';
import Plan from './components/pages/plan/plan'
import UserPlan from './components/pages/plan/UserPlan'
import Category from './components/pages/category/Category'
import Service from './components/pages/services/Service'
import Contact from './components/pages/contact/Contact'




export default function App() {

  return (
    <>
    
      <section className='font-fiexen'>

        <ToastContainer />
        <ChakraProvider>
          <Router>
            <Routes>
              
              {/* AUTH */}
              <Route exact path='/login' element={<Login />} />
             
              <Route exact path='/forget_password' element={<ForgetPassword />} />
              <Route exact path='/verify_otp' element={<OtpPage />} />
              <Route exact path='/new_password' element={<NewPassword />} />
              <Route exact path='/signup' element={<Signup />} />

              {/* HOME */}
              <Route exact path='/' element={<ProtectedRoutes Component = {Home} />} />
              <Route exact path='/users' element={<ProtectedRoutes Component = {Users} />} />
              <Route exact path='/plans' element={<ProtectedRoutes Component = {Plan} />} />
              <Route exact path='/user_plans' element={<ProtectedRoutes Component = {UserPlan} />} />
              <Route exact path='/categories' element={<ProtectedRoutes Component = {Category} />} />
              <Route exact path='/services' element={<ProtectedRoutes Component = {Service} />} />
              <Route exact path='/contacts' element={<ProtectedRoutes Component = {Contact} />} />
            
              
            </Routes>
          </Router>
         
        </ChakraProvider>
      </section>
    </>
  )
}
