import React from "react";
import { Link } from "react-router-dom";
import Progress from "./Progress";

export default function Card(props) {
  const { Icon } = props;
  return (
    <Link
      to={props.location}
      className="bg-white shadow-lg py-4 px-5 rounded-lg space-y-5 hover:text-white hover:bg-themeBlue focus:text-white focus:bg-themeBlue"
    >
      <div className="flex items-center justify-between">
        <h2 className="font-bold text-2xl">{props.quantity}</h2>
        <span className={`${props.colorScheme} p-3 rounded-xl`}>
          <Icon size={32} className="text-white" />
        </span>
      </div>
      <h5 className="font-normal text-lg">{props.title}</h5>
      <div className="space-y-1">
        {/* <div className="font-normal text-xl flex items-center justify-between">
          <span>0%</span>
          <span>{props.size}%</span>
        </div> */}
        {/* <Progress colorScheme={props.colorScheme} size={props.size} /> */}
      </div>
    </Link>
  );
}
