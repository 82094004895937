import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { PostApi } from "../../../ApiClient/PostApi";
import Sidebar from "./Sidebar";
import { error_toaster, success_toaster } from "../../toaster/Toaster";
import { setLoginStatus } from "../../../Utils/AuthCheck";
import { Loader2 } from "../../loader/Loader";
// import  {useSelector,useDispatch} from "react-redux";
// import { incNumber } from '../../../action/index';

export default function Login() {
  // const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState({ email: "", password: "" });
  const [loading, setLoading] = useState(false);
  const onChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const login = async (event) => {
    event.preventDefault();

    if (data.email === "") {
      error_toaster("Email is required!");
      return false;
    } else if (data.password === "") {
      error_toaster("Password is required!");
      return false;
    } else {
      setLoading(true);
      const res = await PostApi("login", {
        email: data.email,
        password: data.password,
      });
      console.log(res);

      if (res.data.status === "1") {
        setLoading(false);
        setLoginStatus(false);
        localStorage.setItem("accessToken", res.data.data.accessToken);
        localStorage.setItem(
          "permissions",
          JSON.stringify(res?.data?.data?.permissions?.RolePermissions)
        );
        // dispatch(incNumber(JSON.stringify(res?.data?.data?.permissions?.RolePermissions)))
        navigate("/");
        success_toaster(res.data.message);
      } else {
        setLoading(false);
        error_toaster(res.data.message);
      }
    }
  };
  const body = "body {background: #FFFFFF !important}";
  const inputStyle =
    "bg-themeGray border-none font-normal sm:text-xl text-lg px-5 py-3 rounded-lg placeholder:text-black placeholder:text-opacity-40 focus:outline-none";
  return (
    <>
      <style>{body}</style>
      <section className="grid lg:grid-cols-2 h-[100vh]">
        <Sidebar />
        {loading ? (
          <Loader2 />
        ) : (
          <div className="xl:w-1/2 lg:w-3/4 md:w-1/2 sm:w-2/3 w-4/5 mx-auto flex flex-col justify-center gap-y-8">
            <h1 className="font-bold sm:text-5xl text-4xl">Sign in</h1>
            <div className="flex flex-col gap-y-4">
              <input
                type="email"
                name="email"
                onChange={onChange}
                value={data.email}
                placeholder="Email"
                className={inputStyle}
              />
              <input
                type="password"
                onChange={onChange}
                value={data.password}
                name="password"
                placeholder="Password"
                className={inputStyle}
              />
            </div>
            <button
              onClick={() => navigate("/forget_password")}
              className="font-normal text-base text-right"
            >
              Forgot your password?
            </button>
            <button
              onClick={login}
              className="bg-themeBlue text-white font-medium sm:text-xl text-lg py-3 px-8 rounded-md w-full border border-themeBlue hover:bg-transparent hover:text-themeBlue"
            >
              Sign in
            </button>
            <Link to={"/signup"} className="font-normal text-xl text-center">
              Don’t have a account?
            </Link>
            <Link
              to={"/signup"}
              className="text-themeBlue font-medium sm:text-xl text-lg text-center py-3 px-8 rounded-md w-full border border-themeBlue hover:bg-themeBlue hover:text-white"
            >
              Create new account
            </Link>
          </div>
        )}
      </section>
    </>
  );
}
