import React from "react";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { PostApi } from "../../../ApiClient/PostApi";
import { error_toaster, success_toaster } from "../../toaster/Toaster";
import ListItem, { MiniItem } from "./leftbarElements/ListItem";

export default function Leftbar() {
  const location = useLocation().pathname;
  const navigate = useNavigate();
  const [loading, SetLoading] = useState(false);
  const logoutAdmin = async () => {
    SetLoading(true);
    localStorage.removeItem("loginstatus");
    localStorage.removeItem("accessToken");
    navigate("/login");
    // const res = await PostApi("logout", {
    //   accessToken: localStorage.getItem("accessToken"),
    // });
    // if (res.data.status === "1") {
    //   success_toaster(res.data.message);
    //   SetLoading(false);
    //   localStorage.removeItem("loginstatus");
    //   localStorage.removeItem("accessToken");
    //   navigate("/login");
    // } else {
    //   error_toaster(res.data.message);
    //   SetLoading(false);
    // }
  };

  return (
    <div className="bg-themeBlue xl:w-[280px] w-60 fixed h-full py-7 pl-8 pr-5 flex flex-col gap-y-12">
      <Link to={"/"}>
        <p className="text-white font-semibold text-xl">Project Name</p>
      </Link>
      <div className="h-full">
        <ul className="flex flex-col gap-y-7 h-4/5 overflow-auto sidebar">
          <ListItem
            path="/"
            text="Dashboard"
            icon="1"
            opacity={location === "/" ? "opacity-100" : "opacity-60"}
          />

          <ListItem
            path="/users"
            text="User Management"
            icon="6"
            opacity={
              location === "/users" || location === "/users/user_details"
                ? "opacity-100"
                : "opacity-60"
            }
          />

          <ListItem
            path="/plans"
            text="Plan's Managment"
            icon="6"
            opacity={location === "/plans" ? "opacity-100" : "opacity-60"}
          />
          <ListItem
            path="/user_plans"
            text="User's Plan"
            icon="6"
            opacity={location === "/user_plans" ? "opacity-100" : "opacity-60"}
          />
          <ListItem
            path="/categories"
            text="Categories"
            icon="6"
            opacity={location === "/categories" ? "opacity-100" : "opacity-60"}
          />
          <ListItem
            path="/services"
            text="Services"
            icon="6"
            opacity={location === "/services" ? "opacity-100" : "opacity-60"}
          />
          <ListItem
            path="/contacts"
            text="Contacts"
            icon="6"
            opacity={location === "/contacts" ? "opacity-100" : "opacity-60"}
          />
        </ul>
        <div className="flex items-center gap-x-8 absolute bottom-7">
          <MiniItem path="/" icon="10" />
          <MiniItem path="/warehouse_address" icon="11" />
          {loading ? (
            <p>Loading...</p>
          ) : (
            <button onClick={logoutAdmin} style={{ color: "white" }}>
              <MiniItem icon="12" />
            </button>
          )}

          {/* <MiniItem  icon="12" /> */}
        </div>
      </div>
    </div>
  );
}
